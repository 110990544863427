$primaryColor: #4945ff;
$primaryDarkColor: #4945ff;

$secondaryColor: #6762fd;
$secondaryDarkColor: #3d36fc;

$successColor: #328048;
$successDarkColor: #5CB176;

$dangerColor: #ea4335;
$dangerDarkColor: #f47265;

$warningColor: #ffb600;
$warningDarkColor: #ffd76f;

$facebookColor: #2f80ed;
$facebookDarkColor: #1766cc;

$lineColor: #00b900;
$lineDarkColor: #0a8f0a;

$goldColor: #c19c5f;
$goldDarkColor: #c19c5f;

$gradienColor: linear-gradient(#7c4dff, #df12b2);
$gradienDarkColor: linear-gradient(#f581ff, #ffb388);

$bluePallete: (
  1: #eceff1,
  2: #cfd8dc,
  3: #b0bec5,
  4: #90a4ae,
  5: #78909c,
  6: #607d8b,
  7: #546e7a,
  8: #455a64,
  9: #37474f,
  10: #263238
);

$colorPallete: (
  1: #fafafa,
  2: #f5f5f5,
  3: #eeeeee,
  4: #e0e0e0,
  5: #bdbdbd,
  6: #9e9e9e,
  7: #757575,
  8: #616161,
  9: #424242,
  10: #212121
);
$fontFimary: "Sarabun", sans-serif;
