.input-icon-end {
  position: relative;
}
.input-icon-end input {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid gainsboro;
  padding-right: 45px;
}
.input-icon-end span {
  position: absolute;
  right: 9px;
  top: 7px;
}

.input-icon-start {
  position: relative;
}
.input-icon-start input {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid gainsboro;
  padding-left: 45px;
}
.input-icon-start span {
  position: absolute;
  left: 9px;
  top: 7px;
}

.h-48 {
  height: 48px;
}

:host ::ng-deep p-menubar {
  width: 50px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.p-dialog .p-dialog-content {
  overflow-y: visible !important;
}

.material-icons {
  cursor: pointer;
  font-size: 21px;
}
.material-icons.edit:hover {
  color: #e97c1f;
}
.material-icons.delete:hover {
  color: #d92a32;
}

b,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #37474f;
}

html,
body {
  font-family: "Sarabun", sans-serif;
  font-size: 14px;
}
html,
html a,
body,
body a {
  color: #78909c;
}

body.auth-layout {
  background-color: #eceff1;
}

.iti,
.iti--allow-dropdown {
  width: 100% !important;
}

.tt {
  width: 250px !important;
}

p-dropdown > .p-dropdown {
  width: 100% !important;
}

.web .flex {
  display: flex;
}
.web .text-center {
  display: flex;
  justify-content: center;
}
.web h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  color: #212121;
}
.web :host ::ng-deep .p-dialog-content {
  overflow-y: unset !important;
}
.web .def {
  background-color: #efefef !important;
  color: black;
}
.web .def:hover {
  color: black !important;
}
.web .primary {
  background-color: #605dec !important;
  color: white;
}
.web .create,
.web .create:hover {
  background-color: #bf9b5f;
  color: white;
}
.web .btn {
  margin-top: 35px;
  padding-left: 28px;
  height: 45px;
}
.web .input-right {
  position: relative;
}
.web .input-right span {
  position: absolute;
  right: 13px;
  top: 11px;
}
.web .input-right input {
  padding-right: 38px;
}
.web .input-left {
  position: relative;
}
.web .input-left span {
  position: absolute;
  left: 13px;
  top: 11px;
}
.web .input-left input {
  padding-left: 38px;
}

.line {
  background: linear-gradient(0deg, #000000 14.42%, #59534a 109.34%) !important;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.line .box-number,
.line .otp-input {
  color: white !important;
  font-size: 20px !important;
  text-align: center !important;
  border: none !important;
  background: #696359 !important;
  border-radius: 4px !important;
  height: 65px !important;
  width: 100% !important;
}
.line .box-number.otp,
.line .otp-input.otp {
  padding-top: 17px !important;
}
.line .box-number .wrapper,
.line .otp-input .wrapper {
  display: flex !important;
}
.line .icon-left,
.line .icon-start {
  position: relative !important;
  padding-left: 62px !important;
  padding-top: 4px !important;
  margin: 15px 0px !important;
  font-size: 20px !important;
}
.line .icon-left span,
.line .icon-left .material-icons,
.line .icon-start span,
.line .icon-start .material-icons {
  position: absolute !important;
  left: 13px !important;
  top: 0;
  font-size: 40px !important;
}
.line .icon-right,
.line .icon-end {
  position: relative !important;
  padding-left: 62px !important;
  padding-top: 4px !important;
  margin: 15px 0px !important;
  font-size: 20px !important;
}
.line .icon-right span,
.line .icon-right .material-icons,
.line .icon-end span,
.line .icon-end .material-icons {
  position: absolute !important;
  left: 13px !important;
  top: 0 !important;
  font-size: 40px;
}
.line .center {
  position: relative;
  margin: 15px 0px;
  font-size: 20px;
  text-align: center;
}

.p-btn-default {
  color: black;
  background-color: #e0e0e0 !important;
}
.p-btn-default:hover {
  background-color: #bdbdbd !important;
}

.p-btn-primary {
  color: white;
  background-color: #4945ff !important;
}
.p-btn-primary:hover {
  background-color: #4945ff !important;
}

.p-btn-danger {
  color: white;
  background-color: #ea4335 !important;
}
.p-btn-danger:hover {
  background-color: #f47265 !important;
}

.p-btn-success {
  color: white;
  background-color: #328048 !important;
}
.p-btn-success:hover {
  background-color: #5CB176 !important;
}

.p-btn-warning {
  color: white;
  background-color: #ffb600 !important;
}
.p-btn-warning:hover {
  background-color: #ffd76f !important;
}

.p-btn-dank {
  color: white;
  background-color: #6762fd !important;
}
.p-btn-dank:hover {
  background-color: #3d36fc !important;
}

.p-button-success {
  background-color: #328048 !important;
}

.p-button-info {
  background-color: #0c75af !important;
}

.p-radiobutton-icon {
  background-color: #4945ff !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight,
.p-checkbox-box {
  border-color: #4945ff !important;
}

.p-checkbox-box.p-highlight {
  background-color: #4945ff !important;
}